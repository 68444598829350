import React from "react";
import {
  styled,
  Container,
  Typography,
  Grid,
  Box,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Logo from "../utils/logo";
import ReactCompareImage from "react-compare-image";
import { IconMenu, IconBrandInstagram, IconBrandGoogle } from "@tabler/icons";

import ImageBefore from "../../assets/b.png";
import ImageAfter from "../../assets/a.png";
import { GoogleAuthProvider } from "firebase/auth";
import { signInWithProvider } from "../../contexts/userContext";

const PREFIX = "Home";

const classes = {
  container: `${PREFIX}-container`,
  heroSection: `${PREFIX}-heroSection`,
  heroContent: `${PREFIX}-heroContent`,
  featuresSection: `${PREFIX}-featuresSection`,
  featureItem: `${PREFIX}-featureItem`,
  footer: `${PREFIX}-footer`,
  action: `${PREFIX}-action`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.container}`]: {
    backgroundColor: theme.palette.background.default,
  },
  [`& .${classes.heroSection}`]: {
    backgroundColor: theme.palette.grey[900],
    color: theme.palette.common.white,
    padding: theme.spacing(3, 0),
  },
  [`& .${classes.heroContent}`]: {
    textAlign: "center",
    padding: theme.spacing(4, 0),
  },
  [`& .${classes.featuresSection}`]: {
    padding: theme.spacing(8, 0),
  },
  [`& .${classes.featureItem}`]: {
    textAlign: "center",
    padding: theme.spacing(2),
  },
  [`& .${classes.footer}`]: {
    backgroundColor: theme.palette.grey[900],
    color: theme.palette.common.white,
    padding: theme.spacing(4),
  },
  [`& .${classes.action}`]: {
    marginTop: theme.spacing(2),
  },
}));

export default function Home() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    await signInWithProvider(provider).then(() => navigate("/app/recents"));
  };

  return (
    <StyledBox className={classes.container}>
      <AppBar position="static" color="transparent" elevation={0}>
        <Toolbar>
          <Logo />
          <Box sx={{ flexGrow: 1 }} />
          {isMobile ? (
            <IconButton edge="end" color="inherit" aria-label="menu">
              <IconMenu />
            </IconButton>
          ) : (
            <></>
          )}
        </Toolbar>
      </AppBar>

      {/* Hero Section */}
      <Box className={classes.heroSection}>
        <Container maxWidth="md">
          <Box className={classes.heroContent}>
            <Typography variant="h4" component="h1" gutterBottom>
              Explore empty interiors using AI.
            </Typography>
            <Typography variant="h6" paragraph>
              Visualize your dream interior instantly using our AI-powered
              virtual staging tool.
            </Typography>
            <Button
              size="large"
              variant="contained"
              className={classes.action}
              onClick={signInWithGoogle}
              startIcon={<IconBrandGoogle size={20} strokeWidth={3} />}
            >
              Sign in with Google
            </Button>
          </Box>
        </Container>
      </Box>

      {/* Before and After Comparison */}
      <Container
        sx={{ marginTop: theme.spacing(4), marginBottom: theme.spacing(4) }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            padding: theme.spacing(3, 0),
          }}
        >
          <Box
            sx={{
              width: isMobile ? "100%" : "70%",
              borderRadius: theme.shape.borderRadius,
              overflow: "hidden",
            }}
          >
            <ReactCompareImage
              leftImage={ImageBefore}
              rightImage={ImageAfter}
              leftImageLabel="Before"
              rightImageLabel="After"
            />
          </Box>
        </Box>
      </Container>

      {/* Feature Highlights */}
      <Container className={classes.featuresSection}>
        <Typography variant="h4" align="center" gutterBottom>
          How Virtual Staging Works
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Box className={classes.featureItem}>
              <Typography variant="h6">1. Upload Your Photo</Typography>
              <Typography>
                Take a picture of your empty room and upload it to our platform.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box className={classes.featureItem}>
              <Typography variant="h6">2. Choose Your Style</Typography>
              <Typography>
                Select from various interior design styles or create your own.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box className={classes.featureItem}>
              <Typography variant="h6">3. Get AI-Generated Results</Typography>
              <Typography>
                Receive beautifully staged images of your space in seconds.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>

      {/* Footer */}
      <Box className={classes.footer}>
        <Container>
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <Typography variant="h6">About interioria</Typography>
              <Typography variant="body2">
                We're revolutionizing virtual staging with AI technology, making
                beautiful spaces accessible to everyone.
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="h6">Contact</Typography>
              <Typography variant="body2">
                Email: robertovclopes@gmail.com
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="h6">Follow Us</Typography>
              <IconButton
                color="inherit"
                href="https://instagram.com/yougenie.co"
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconBrandInstagram />
              </IconButton>
            </Grid>
          </Grid>
          <Box sx={{ textAlign: "center", marginTop: theme.spacing(2) }}>
            <Typography variant="body2">
              &copy; {new Date().getFullYear()} interioria. All rights reserved.
            </Typography>
          </Box>
        </Container>
      </Box>
    </StyledBox>
  );
}
