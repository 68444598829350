import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";

import theme from "./theme";
import { ThemeProvider, CssBaseline, Box, useMediaQuery } from "@mui/material";

import Home from "./components/pages/home";
import SideBar from "./components/utils/sideBar";
import Projector from "./components/project";
import Recents from "./components/pages/recents";
import PopMenu from "./components/utils/popMenu";
import VirtualStager from "./components/virtual-stager";

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="app" element={<Application />}>
            <Route path="/app/recents" element={<Recents />}></Route>
            <Route
              path="/app/virtual-stager"
              element={<VirtualStager />}
            ></Route>
            <Route path="/app/project/:id" element={<Projector />}></Route>
          </Route>
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

function Application() {
  const desktop = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <div>
      <Box display="flex" flexDirection={desktop ? "row" : "column"}>
        {desktop ? <SideBar /> : <PopMenu />}
        <Outlet />
      </Box>
    </div>
  );
}
