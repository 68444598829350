import React, { useEffect, useState, useContext, useCallback } from "react";
import {
  styled,
  Container,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
  Button,
  Box,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import Project from "../../models/project";
import { map, orderBy } from "lodash";
import ProjectDb from "../../db/projectDb";
import { getDocs, query } from "firebase/firestore";
import { UserContext } from "../../contexts/userContext";

const PREFIX = "recents";

const classes = {
  container: `${PREFIX}-container`,
  breadcrumbs: `${PREFIX}-breadcrumbs`,
  help: `${PREFIX}-help`,
};

const StyledContainer = styled(Container)(({ theme }) => ({
  [`&.${classes.container}`]: {
    padding: theme.spacing(4),
  },
  [`& .${classes.breadcrumbs}`]: {
    fontWeight: 700,
    padding: theme.spacing(0, 0, 4, 0),
  },
  [`& .${classes.help}`]: {
    fontStyle: "italic",
    padding: theme.spacing(2, 0, 0, 0),
    color: theme.palette.secondary.main,
  },
}));

export default function Recents() {
  const navigate = useNavigate();
  const user = useContext(UserContext);
  const [projects, setProjects] = useState<Project[]>();

  useEffect(() => {
    if (user)
      getDocs(
        query(
          new ProjectDb().collection({
            userId: user.id,
            projectId: "",
          })
        )
      ).then((snapshot) => {
        const projectsSnapshot = orderBy(
          map(snapshot.docs, (doc) => doc.data()),
          (project) => project.dateCreated,
          "desc"
        );
        setProjects(projectsSnapshot);
      });
  }, [user]);

  const handleOnClick = useCallback(
    async (link: string) => {
      navigate(link);
    },
    [navigate]
  );

  return (
    <StyledContainer className={classes.container}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h5" className={classes.breadcrumbs}>
            Projects
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <Button
              size="large"
              variant="contained"
              onClick={() => handleOnClick(`/app/virtual-stager`)}
            >
              Create project (Virtual Stager)
            </Button>
          </Box>
          <Box paddingY={2} />
          {projects && projects.length > 0 && (
            <List>
              {projects?.map((project) => (
                <ListItem
                  button
                  key={project.id}
                  disablePadding={true}
                  onClick={() => navigate(`/app/project/${project.id}`)}
                >
                  <ListItemText primary={`Project / ${project.id}`} />
                  <ListItemText
                    primary={`${project.dateCreated.format("MM/DD/YY")}`}
                  />
                </ListItem>
              ))}
            </List>
          )}
        </Grid>
      </Grid>
    </StyledContainer>
  );
}
